//
// features.scss
//

/*********************************/
/*     Features & Services       */
/*===============================*/
@each $name,
$value in $theme-colors {
  .features {
    &.feature-#{$name} {
      .icon {
        background: rgba($value, 0.1);
      }

      .image {
        &:before {
          background: linear-gradient(45deg, transparent, rgba($value, 0.1));
        }
      }

      .read-more,
      .color {
        color: $value !important;
      }

      &.feature-clean {
        .icons {
          color: $value !important;

          i {
            background-color: rgba($value, 0.1);
          }
        }

        .title {
          &:hover {
            color: $value !important;
          }
        }
      }

      &.feature-full-bg {
        .icon-color {
          color: $value !important;
        }

        &:hover {
          background-color: $value !important;
        }
      }

      &.key-feature {
        .icon {
          background: linear-gradient(45deg, transparent, rgba($value, 0.15));
          color: $value;
        }
      }

      &.course-feature {
        .full-img {
          color: $value !important;
        }
      }

      &.explore-feature {
        &:hover {
          .icons,
          .title {
            color: $value !important;
          }
        }
      }

      .btn-soft {
        background-color: rgba($value, 0.05) !important;
        border: 1px solid rgba($value, 0.05) !important;
        color: #{$value} !important;
        box-shadow: 0 3px 5px 0 rgba($value, 0.1);

        &:hover, &:focus, &:active, &.active, &.focus {
          background-color: #{$value} !important;
          border-color: #{$value} !important;
          color: $white !important;
        }
      }

      &:hover {
        .image {
          &:before {
            background: rgba($value, 0.1);
          }
        }
      }
    }
  }
}

.features {
  .image {
    &:before {
      content: "";
      position: absolute;
      bottom: 5px;
      left: 5px;
      width: 64px;
      height: 64px;
      border-radius: 6px;
      transform: rotate(33.75deg);
    }
  }

  &.feature-clean {
    background-color: $body-bg;

    .icons {
      i {
        height: 65px;
        width: 65px;
        display: inline-flex !important;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .ride-image {
    transform: rotate(-45deg);

    img {
      border-radius: 100% 100% 100% 0;
    }
  }

  &.key-feature {
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: $shadow-md !important;
    }
  }

  &:hover {
    .image {
      &:before {
        animation: spinner-border 5s linear infinite !important;
      }
    }
  }

  &.feature-full-bg {
    transition: all 0.5s ease;

    .big-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      opacity: 0.015;
      font-size: 180px;
    }

    &:hover {
      box-shadow: $shadow-lg;
      color: $white !important;

      .icon-color i,
      .para {
        color: rgba($white, 0.5) !important;
      }

      .content,
      .icon-color {
        z-index: 2;
      }

      .big-icon {
        opacity: 0.05;
      }

      .readmore {
        color: $white !important;
      }
    }
  }

  &.course-feature {
    transition: all 0.5s ease;

    .full-img {
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      left: 0;
      right: 0;
      opacity: 0;
      margin: 0 auto;
      z-index: -2;
      transition: all 0.5s ease;
      font-size: 250px;
    }

    &:hover {
      transform: translateY(-10px);

      .full-img {
        opacity: 0.05;
      }
    }
  }

  &.explore-feature {
    transition: all 0.5s ease;

    .icons {
      height: 80px;
      width: 80px;
      display: inline-flex !important;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
    }

    &:hover {
      box-shadow: $shadow-lg !important;
    }
  }
}

.features,
.key-feature {
  .title {
    font-size: 18px !important;
  }

  .icon {
    height: 45px;
    width: 45px;
    min-width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Work process
.work-process {
  transition: all 0.5s ease;

  .step, .step-icon {
    opacity: 0.06;
  }

  a {
    font-size: 15px;
  }

  &.process-arrow {
    &:after {
      content: '';
      position: absolute;
      width: 60%;
      height: 40px;
      top: 30px;
      left: 80%;
      background: url("../images/process.png") center center no-repeat;
      z-index: 1;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &:hover {
      transform: none !important;
    }
  }

  &:hover {
    transform: translateY(-10px);
  }

  &.d-none-arrow {
    &:hover {
      transform: none !important;
    }
  }
}

//index-crypto.html and index-course.html
.features-absolute {
  position: relative;
  z-index: 2;
  transition: all 0.5s ease;
}

.features-absolute {
  margin: -200px 0 0px;

  @media (max-width: 768px) {
    margin: -140px 0 0;
  }

  &.blog-search {
    margin: -120px 0 0px;

    @media (max-width: 768px) {
      margin: -90px 0 0px;
    }
  }
}

//Classic App
.app-feature-shape-right,
.app-feature-shape-left {
  &:after {
    bottom: 0rem;
    width: 30rem;
    height: 40rem;
    border-radius: 10rem;
  }
}

.app-feature-shape-right {
  &:after {
    left: 10rem;
    transform: rotate(150deg);

    @media (max-width: 768px) {
      left: 0;
    }
  }
}

.app-feature-shape-left {
  &:after {
    right: 10rem;
    transform: rotate(210deg);

    @media (max-width: 768px) {
      right: 0;
    }
  }
}

.app-subscribe {
  position: absolute;
  top: -200px;

  @media (max-width: 767px) {
    position: relative;
    top: 0;
  }
}

//Classic Saas
.saas-feature-shape-left,
.saas-feature-shape-right {
  &:after {
    bottom: -3rem;
    width: 35rem;
    height: 35rem;
    border-radius: 15rem;
    transform: rotate(145deg);

    @media (max-width: 767px) {
      bottom: -1rem;
      width: 25rem;
      height: 25rem;
      border-radius: 10rem;
    }
  }
}

.saas-feature-shape-left {
  &:after {
    left: -10rem;
  }
}

.saas-feature-shape-right {
  &:after {
    right: -10rem;
  }
}

.app-feature-shape-right,
.app-feature-shape-left,
.saas-feature-shape-left,
.saas-feature-shape-right,
.social-feature-left,
.social-feature-right {
  &:after {
    background: rgba($primary, 0.1);
    box-shadow: 0 0 40px rgba($primary, 0.1);
  }
}

.app-feature-shape-right,
.app-feature-shape-left,
.saas-feature-shape-left,
.saas-feature-shape-right,
.social-feature-left,
.social-feature-right {
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
  }
}

//Modern Saas 
.modern-saas-absolute-left,
.modern-saas-absolute-right {
  position: absolute;
}

.modern-saas-absolute-left {
  top: 2%;
  left: -2%;

  @media (min-width: 768px) {
    top: 20%;
    left: -7%;
  }

  @media (min-width: 1024px) {
    left: -10%;
  }
}

.modern-saas-absolute-right {
  bottom: 2%;
  right: -2%;

  @media (min-width: 768px) {
    bottom: 20%;
    right: -7%;
  }

  @media (min-width: 1024px) {
    right: -10%;
  }
}

//Modern app
.modern-app-round {
  position: fixed;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  filter: blur(100px);
  pointer-events: none;
  z-index: -2;
  background-color: rgba($primary, 0.15);
}

.modern-app-bg-shape {
  &:after {
    content: " ";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(to right, rgba($primary, 0.3) 0%, rgba($primary, 0.5) 35%, rgba($primary, 1) 100%);
    box-shadow: 0 0 40px rgba($primary, 0.2);
    z-index: -1;

    @media (max-width: 768px) {
      width: 400px;
      height: 400px;
    }
  }
}

.modern-app-absolute-left,
.modern-app-absolute-right {
  position: absolute;
}

.modern-app-absolute-right {
  top: 7%;
  right: -2%;

  @media (min-width: 768px) {
    top: 20%;
    right: -7%;
  }

  @media (min-width: 1024px) {
    right: -10%;
  }
}

.modern-app-absolute-left {
  bottom: 7%;
  left: -2%;

  @media (min-width: 768px) {
    bottom: 20%;
    left: -7%;
  }

  @media (min-width: 1024px) {
    left: -10%;
  }
}

//Social Media Marketing
.social-feature-left,
.social-feature-right {
  &:after {
    width: 450px;
    height: 520px;
    border-radius: 30px;
    bottom: -75px;
    @media (max-width: 768px) {
      width: 300px;
      height: 400px;
      bottom: -65px;
    }

    @media (max-width: 575px) {
      bottom: -35px;
    }
  }
}

.social-feature-left {
  &:after {
    right: -200px;
    @media (max-width: 575px) {
      right: 60px;
    }
  }
}

.social-feature-right {
  &:after {
    left: -200px;
    @media (max-width: 575px) {
      left: 60px;
    }
  }
}

//Construction
.construction-img {
  position: absolute;
  bottom: 0;
  right: 20px;
}

/*********************************/
/*          NFT                  */
/*===============================*/
@each $name,
$value in $theme-colors {
  .nft {
    &.nft-#{$name} {
      .title,
      .read-more {
        &:hover {
          color: $value !important;
        }
      }

      .link {
        color: $value !important;
      }

      .author {
        .name {
          &:hover {
            color: $value !important;
          }
        }
      }

      .bg-soft {
        background-color: rgba($value, 0.1) !important;
        border: 1px solid rgba($value, 0.1) !important;
        color: #{$value} !important;
      }

      .btn {
        background-color: #{$value} !important;
        border: 1px solid #{$value} !important;
        color: $white !important;
        box-shadow: 0 3px 5px 0 rgba($value, 0.1);

        &:hover, &:focus, &:active, &.active, &.focus {
          background-color: darken($value, 10%) !important;
          border-color: darken($value, 10%) !important;
          color: $white !important;
        }
      }
    }
  }
}

.nft {
  &.nft-item {
    transition: all 0.5s ease;

    .nft-image {
      .item-img {
        transition: all 0.5s ease;
      }

      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: all 0.5s ease;
        background: $dark;
      }
    }

    &:hover {
      box-shadow: $shadow-md !important;
    }
  }

  .bid-btn {
    position: absolute;
    transition: all 0.5s ease;
    bottom: -100px;
    right: 0;
    left: 0;
  }

  &.nft-creator {
    transition: all 0.5s ease;

    img {
      transition: all 0.5s ease;
    }

    &:hover {
      img {
        height: 90px !important;
        width: 90px !important;
      }
    }
  }

  &:hover {
    .nft-image {
      .item-img {
        transform: scale(1.1) rotate(3deg);
      }

      .overlay {
        opacity: 0.7;
      }
    }

    .bid-btn {
      bottom: 0;
    }
  }
}