//
// work.scss
//

/*********************************/
/*         Works & Portfolios    */
/*===============================*/
@each $name,
$value in $theme-colors {
  .work-container {
    &.work-#{$name} {
      .title {
        &:hover {
          color: $value !important;
        }
      }

      &.work-modern,
      &.work-creative {
        .work-icon {
          color: $value !important;

          &:hover {
            background: $value !important;
          }
        }
      }

      .btn {
        background-color: #{$value} !important;
        border: 1px solid #{$value} !important;
        color: $white !important;
        box-shadow: 0 3px 5px 0 rgba($value, 0.1);

        &:hover, &:focus, &:active, &.active, &.focus {
          background-color: darken($value, 10%) !important;
          border-color: darken($value, 10%) !important;
          color: $white !important;
        }
      }

      .bg {
        background-color: #{$value} !important;
      }

      .link {
        color: $value !important;
      }
    }
  }
}

.work-container {
  &.work-classic {
    .work-image {
      transition: all 0.5s ease;

      &:hover {
        box-shadow: $shadow;
      }
    }
  }

  .overlay-work,
  .overlay-grid {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: all 0.5s ease;
  }

  .overlay-work {
    background-color: $overlay-work-bg;
  }

  .overlay-grid {
    background: $linear-gradient-4;
  }

  &.work-classic,
  &.work-grid,
  &.work-modern {
    .content {
      .title {
        font-size: 18px;
        transition: all 0.5s ease;
      }

      .tag {
        font-size: 15px !important;
      }
    }
  }

  &.work-modern {
    img,
    .overlay-work,
    .content,
    .client,
    .read_more {
      transition: all 0.5s ease;
    }

    .content,
    .client,
    .read_more {
      position: absolute;
    }

    .overlay-work {
      opacity: 0;
    }

    .content {
      z-index: 1;
      bottom: 5%;
      left: 5%;
    }

    .icons {
      right: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .content,
    .client {
      opacity: 0;
    }

    .client {
      color: $white;
    }

    .client,
    .read_more {
      z-index: 1;
      right: 5%;
      top: 5%;
    }

    .read_more {
      height: 30px;
      width: 30px;
      line-height: 30px;
    }

    &:hover {
      img {
        transform: scale(1.1) rotate(3deg);
      }

      .overlay-work {
        opacity: 0.65;
      }

      .personal-port,
      .content,
      .client {
        opacity: 1;
      }
    }
  }

  &.work-grid {
    transition: all 0.5s ease;

    img {
      transition: all 0.5s ease;
    }

    .content {
      background-color: $content-bg;
    }

    &:hover {
      box-shadow: $shadow;

      img {
        transform: translateY(-40px);
      }
    }
  }

  &.work-grid,
  &.work-creative {
    .content {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      transform: translateY(150px);
      overflow: hidden;
      transition: all 0.5s ease;
      z-index: 1;

      .title {
        transition: all 0.5s ease;
      }
    }

    &:hover {
      .content {
        transform: translateY(0);
      }
    }
  }

  &.work-modern,
  &.work-creative {
    .icons {
      position: absolute;
      z-index: 2;
      opacity: 0;
      transition: all 0.5s ease;

      .work-icon {
        height: 45px;
        width: 45px;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;

        &:hover {
          color: $white !important;
        }
      }
    }

    &:hover {
      .icons {
        opacity: 1;
      }
    }
  }

  &.work-creative {
    .icons {
      top: 0;
      right: 0;
    }

    .overlay-grid {
      transform: translateY(500px);
    }

    &:hover {
      .overlay-grid {
        transform: translateY(0);
      }
    }
  }
}

.work-details {
  ul {
    li {
      b {
        max-width: 90px;
        width: 100%;
        display: inline-block;
      }
    }
  }
}

//container filter
.container-filter {
  li {
    font-size: 13px;
    padding: 0px 15px;
    margin: 5px 3px;
    font-weight: 700;
    letter-spacing: 0.8px;
    cursor: pointer;
    line-height: 34px;
    transition: all 0.5s ease;

    &.active,
    &:hover {
      color: $primary !important;
      border-color: $primary !important;
    }
  }

  &.filter-border {
    li {
      color: $muted !important;
      border-bottom: 2px solid transparent;

      &.active,
      &:hover {
        color: $primary !important;
      }
    }
  }
}

.spacing {
  padding: 7px;
}


/*********************************/
/*      Travel                   */
/*===============================*/
.popular-tour {
  .content {
    position: absolute;
    bottom: 3%;
    left: 5%;
  }

  .overlay-work {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all 0.5s ease;
  }

  &:hover {
    .overlay-work {
      opacity: 0.6;
    }
  }
}

/*********************************/
/*         Events                */
/*===============================*/
//Event (index-event.html)
@each $name,
$value in $theme-colors {
  .event-schedule {
    &.event-#{$name} {
      .date {
        color: $value !important;

        .day {
          background: rgba($value, 0.1);
          box-shadow: 0px 0px 2px 0.25px rgba($value, 0.5);
        }
      }

      .content {
        .title {
          &:hover {
            color: $value !important;
          }
        }
      }

      &:hover {
        border-color: $value !important;
      }
    }
  }
}

.event-schedule {
  transition: all 0.5s ease;

  .date {
    .day {
      font-size: 18px;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      line-height: 48px;
      border: 2px solid $border-color;
    }

    .month {
      font-size: 13px;
    }
  }

  .content {
    .title {
      font-size: 20px;
      transition: all 0.5s ease;
    }

    .location-time {
      font-size: 14px;
    }
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: $shadow;
  }
}

/*********************************/
/*         Shopping              */
/*===============================*/
//Shopping
.shop-features {
  .category-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    z-index: 1;
  }
}

.shop-list {
  .shop-image {
    .overlay-work,
    .shop-icons {
      position: absolute;
      opacity: 0;
      transition: all 0.5s ease;
    }

    .overlay-work {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      .out-stock {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .shop-icons {
      top: 15px;
      right: 15px;
      z-index: 2;
    }

    &:hover {
      .overlay-work,
      .shop-icons {
        opacity: 1;
      }
    }
  }

  .content {
    .product-name {
      transition: all 0.5s ease;

      &:hover {
        color: $primary !important;
      }
    }
  }

  .label {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
  }

  .qty-btn {
    pointer-events: none;
    width: 65px;
    padding-left: 15px;
  }
}

/*********************************/
/*         Timeline              */
/*===============================*/
//Timeline
.main-icon {
  border: 2px solid $border-color;
  height: 40px;
  width: 40px;
  line-height: 0;
  text-align: center;

  .fea {
    height: 20px;
  }

  @media (min-width: 768px) {
    margin: 0 auto;
  }
}

.timeline-page {
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 95%;
    margin: auto;
    width: 2px;
    background-color: $gray-200 !important;
  }

  .timeline-item {
    .date-label-left, .duration-right {
      margin-right: 15px;

      &:after {
        content: "";
        position: absolute;
        top: 12px;
        width: 12px;
        height: 12px;
        z-index: 1;
        background-color: $primary;
        border-radius: 4px;
        transform: rotate(45deg);
      }
    }

    .date-label-left {
      float: right;
      margin-right: 15px;

      &:after {
        right: -34px;
      }
    }

    .duration-right {
      float: left;
      margin-left: 15px;

      &:after {
        left: -34px;
      }
    }

    .event {
      transition: all 0.2s ease;

      &.event-description-right {
        margin-left: 15px;
      }

      &.event-description-left {
        margin-right: 15px;
        text-align: right;
      }
    }
  }
}


@media (max-width: 767px) {
  .timeline-page {
    margin-left: 18px;

    &:after {
      margin: 0;
    }

    .timeline-item {
      .duration {
        float: left !important;
        margin: 0 0 30px 15px !important;
        text-align: left !important;

        &:after {
          left: -21px !important;
        }

        .event {
          text-align: left !important;
          margin-left: 15px;
        }
      }

      .event-description-left {
        text-align: left !important;
        margin: 0 0 0 15px;
      }
    }
  }
}

/*********************************/
/*         Tobii Lightbox        */
/*===============================*/
//Tobii Lighbox
.tobii > button.tobii__close svg,
.tobii > button.tobii__prev svg,
.tobii > button.tobii__next svg {
  height: 36px;
  width: auto;
}

.tobii__counter {
  font-size: 16px;
}

.tobii-zoom {
  display: block !important;
}

.tobii-zoom__icon {
  display: none;
}

#grid {
  padding: 0 !important;
}
