//
// countdown.scss
//

/*********************************/
/* Countdown(Comingsoon, Maintenance) */
/*===============================*/

//countdown
.coming-soon, .maintenance, .error-page {
  font-size: 40px;
  letter-spacing: 1px;

  @media (max-width: 767px) {
    font-size: 32px;
  }
}

#countdown, #eventdown, #token-sale {
  .count-down {
    display: inline-block;
    margin: 30px 10px 10px;
    color: $white;
    text-align: center;

    .count-number {
      font-size: 40px;
      line-height: 95px;
    }

    .count-head {
      display: block;
      position: relative;
      transform: translateY(-20px);
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}

#countdown {
  .count-down {
    .count-number {
      font-size: 40px;
      line-height: 95px;
      border: 4px solid $white;
      height: 130px;
      width: 130px;
      border-radius: 50%;
    }
  }
}

//token-sale
#token-sale {
  .count-down {
    border: 0;
    color: $dark !important;

    .count-number {
      line-height: 80px;
      @media (max-width: 767px) {
        font-size: 30px;
      }
    }

    .count-head {
      margin-bottom: 0 !important;
      transform: translateY(-15px);
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
  }
}

//Event(index-event.html)
#eventdown {
  .count-down {
    margin: 10px !important;
    border: 0;
    @media (max-width: 767px) {
      width: 60px;
    }

    .count-number {
      line-height: 80px;
      @media (max-width: 767px) {
        font-size: 30px;
      }
    }

    .count-head {
      transform: translateY(-15px);
      @media (max-width: 767px) {
        font-size: 10px;
      }
    }
  }
}

//maintenance
#maintenance {
  color: $white;
  font-size: 56px;
  letter-spacing: 1px;

  .indicator {
    font-size: 18px;
  }
}